@use 'breakpoints';
@import 'mixins';

.body {
	padding: 1rem;
	padding-bottom: 2rem;

	@media (min-width: breakpoints.$medium) {
		min-height: auto;
		padding: 0;
	}
}

.name {
	margin: 0 auto 8px;
}

.name,
.subtitle {
	text-align: center;
}

.formWrapper {
	min-height: px2rem(100);
	padding-top: 1rem;
	padding-bottom: 1rem;
}
